<div class="bo-file" [class.disabled]="disabled">
  <input
    #fileUpload
    type="file"
    class="bo-file__input"
    [multiple]="multi"
    [accept]="accept"
    (change)="onFileSelected($event)"
  />
  <div
    class="bo-file__trigger"
    (click)="fileUpload.click(); $event.stopPropagation(); $event.preventDefault()"
  >
    <ng-container *ngTemplateOutlet="triggerTemplate || defaultTriggerTemplate"></ng-container>
  </div>

  <ng-template #defaultTriggerTemplate>
    <app-button
      [text]="'MTR_COMMON_CHOOSE_MULTIPLE' | translate"
      [disabled]="disabled"
      extraClasses="bo-button--secondary"
    />
  </ng-template>
</div>
