import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  inject,
  Input,
} from '@angular/core'
import { NG_VALUE_ACCESSOR } from '@angular/forms'

import { Checkbox } from 'primeng/checkbox'

@Component({
  selector: 'app-checkbox',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
  templateUrl: 'checkbox.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent extends Checkbox {
  private _changeDetector = inject(ChangeDetectorRef)
  indeterminateIcon = 'pi pi-minus'

  @Input() enableIndeterminate = false
  @Input() qaId: string
  @Input() override checkboxIcon = 'pi pi-check'

  /**
   * Sets the disabled state of the control. Implemented as a part of ControlValueAccessor.
   * @param isDisabled Whether the control should be disabled.
   */
  override setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled
    this._changeDetector.markForCheck()
  }
}
