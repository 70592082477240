<div
  [ngStyle]="style"
  [ngClass]="{
    'p-checkbox p-component': true,
    'p-checkbox-checked': checked(),
    'p-checkbox-disabled': disabled,
    'p-checkbox-focused': focused
  }"
  [class]="styleClass"
  [attr.data-pc-name]="'checkbox'"
  [attr.data-pc-section]="'root'"
  (click)="onClick($event, null, null)"
>
  <div
    class="p-hidden-accessible"
    [attr.data-pc-section]="'hiddenInputWrapper'"
    [attr.data-p-hidden-accessible]="true"
  >
    <input
      #input
      [attr.id]="inputId"
      type="checkbox"
      [value]="value"
      [attr.name]="name"
      [checked]="checked()"
      [attr.tabindex]="tabindex"
      [disabled]="disabled"
      [readonly]="readonly"
      [attr.required]="required"
      [attr.aria-labelledby]="ariaLabelledBy"
      [attr.aria-label]="ariaLabel"
      [attr.aria-checked]="checked()"
      [indeterminate]="enableIndeterminate && checked() == null"
      (focus)="onInputFocus($event)"
      (blur)="onInputBlur($event)"
      [attr.data-pc-section]="'hiddenInput'"
    />
  </div>
  <div
    class="p-checkbox-box"
    [ngClass]="{
      'p-highlight': checked(),
      'p-disabled': disabled,
      'p-focus': focused,
      'p-indeterminate': enableIndeterminate && checked() == null
    }"
    [attr.data-p-highlight]="checked()"
    [attr.data-p-disabled]="disabled"
    [attr.data-p-focused]="focused"
    [attr.data-p-indeterminate]="enableIndeterminate && checked() == null"
    [attr.data-pc-section]="'input'"
  >
    <ng-container *ngIf="checked()">
      <ng-container *ngIf="!checkboxIconTemplate">
        <span
          *ngIf="checkboxIcon"
          class="p-checkbox-icon"
          [ngClass]="
            checked()
              ? checkboxIcon
              : enableIndeterminate && checked() == null
                ? indeterminateIcon
                : null
          "
          [attr.data-pc-section]="'icon'"
        ></span>
        <CheckIcon
          *ngIf="!checkboxIcon"
          [styleClass]="'p-checkbox-icon'"
          [attr.data-pc-section]="'icon'"
        />
      </ng-container>
      <span *ngIf="checkboxIconTemplate" class="p-checkbox-icon" [attr.data-pc-section]="'icon'">
        <ng-template *ngTemplateOutlet="checkboxIconTemplate"></ng-template>
      </span>
    </ng-container>
  </div>
</div>
<label
  (click)="onClick($event, null, null)"
  [class]="labelStyleClass"
  [style.cursor]="disabled ? '' : 'pointer'"
  [ngClass]="{
    'p-checkbox-label': true,
    'p-checkbox-label-active': checked(),
    'p-disabled': disabled,
    'p-checkbox-label-focus': focused,
    'p-indeterminate': enableIndeterminate && checked() == null
  }"
  *ngIf="label"
  [attr.for]="inputId"
  [attr.data-pc-section]="'label'"
>
  {{ label | translate }}</label
>
