import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core'
import { FileError, FileItem, FileItemError } from '@shared/components/file/models/file.models'
import { exists } from '@mediacoach/ui'
import { getFileSize } from '@shared/components/file/utils/file.utils'

@Component({
  selector: 'bo-file',
  templateUrl: './file.component.html',
  styleUrl: './file.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileComponent {
  private readonly _maxSize = 1000 * 1024

  @Input() multi: boolean = false
  @Input() accept: string
  @Input() maxSize: number = this._maxSize
  @Input() maxSelectedFiles: number
  @Input() disabled: boolean
  @Input() triggerTemplate: TemplateRef<any>
  @Input() triggerText: string
  @Input() sizeErrorText: string = 'MTR_COMMON_SIMPLE_FILE_UPLOAD_ERROR_SIZE_MESSAGE'
  @Input() limitErrorText: string = 'MTR_COMMON_SIMPLE_FILE_UPLOAD_ERROR_LIMIT_MESSAGE'

  @Output() selectedFiles = new EventEmitter<FileItem[]>()
  @Output() fileError = new EventEmitter<FileError>()

  private get maxFileSize() {
    return this.maxSize ?? this._maxSize
  }

  onFileSelected(event: any) {
    if (event?.target?.files?.length) {
      if (exists(this.maxSelectedFiles) && event?.target?.files?.length > this.maxSelectedFiles) {
        this.fileError.emit({
          i18nError: this.limitErrorText,
          i18nErrorInterpolateParams: { limit: this.maxSelectedFiles },
        })
      } else {
        const { files, errors } = this._mapFilesAndErrors(event.target?.files)

        if (files?.length) {
          this.selectedFiles.emit(files)
        }
        if (errors.length) {
          this.fileError.emit({ files: errors })
        }
      }
    }
  }

  private _mapFilesAndErrors(list: FileList): { files: FileItem[]; errors: FileItemError[] } {
    const files = []
    const errors = []
    Array.from(list).forEach((file: File) => {
      if (file.size <= this.maxFileSize) {
        files.push({
          file,
          formattedSize: getFileSize(file.size),
        })
      } else {
        errors.push({
          file,
          i18nError: this.sizeErrorText,
          i18nErrorInterpolateParams: {
            name: file.name,
            size: getFileSize(file.size),
            limit: getFileSize(this.maxFileSize),
          },
        })
      }
    })

    return { files, errors }
  }
}
